import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Landing from "pages/landing";
import Hired from "pages/hired";
import Find from "pages/find";
import Other from "pages/other";
import Policy from "pages/policy";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Landing /> },
    { path: "*", element: <NotFound /> },
    {
      path: "gethired",
      element: <Hired />,
    },
    {
      path: "findtalent",
      element: <Find />,
    },

    {
      path: "other",
      element: <Other />,
    },
    {
      path: "policy",
      element: <Policy />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
