"use client";

import { useWindowSize } from "@react-hook/window-size";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useRafLoop } from "react-use";
import { twMerge } from "tailwind-merge";

const MarqueeItem = ({ children, speed }) => {
  const itemRef = useRef(null);
  const [x, setX] = useState(0);
  const [width, height] = useWindowSize();

  // Only update the item’s bounding rect on initial render or when window size changes
  const [itemWidth, setItemWidth] = useState(0);

  useEffect(() => {
    if (itemRef.current) {
      setItemWidth(itemRef.current.getBoundingClientRect().width);
    }
  }, [width, height]);

  const loop = () => {
    if (itemWidth === 0) return; // Avoid updates if the width isn't available yet

    const newX = x - speed;
    setX(newX);

    itemRef.current.style.transform = `translate3d(${newX}px, 0, 0)`;
  };

  // Initialize the loop once the component mounts
  const [_, loopStart] = useRafLoop(loop, false);

  useEffect(() => {
    loopStart();
  }, [loopStart]);

  return (
    <motion.div ref={itemRef} className="flex items-center justify-center">
      {children}
    </motion.div>
  );
};

export const Marquee = ({ children, speed = 1 }) => {
  const marqueeRef = useRef(null);

  // Duplicate content twice for seamless looping
  const duplicateContent = (
    <div className="flex flex-row">
      {children}
      {children} {/* Duplicate content for seamless loop */}
    </div>
  );

  return (
    <div className="w-full whitespace-nowrap overflow-hidden" ref={marqueeRef}>
      <motion.div className={twMerge("flex flex-row")}>
        <MarqueeItem speed={speed}>{duplicateContent}</MarqueeItem>
      </motion.div>
    </div>
  );
};