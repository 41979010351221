import React from "react";
import { Img } from "../../components";

export function Navigation() {
  return (
    <div>
      <section className="p-5 mt-[-3%] bg-black font-saira">
        <div className="max-w-screen-xl px-4 mx-auto overflow-hiddensm:px-6 lg:px-8">
          <nav className="flex justify-between sm:flex-col items-center">
            <a
              href="/"
              className="text-base leading-6 text-white hover:text-orange/100 mb-3"
            >
              <Img
                src="images/talentsV2.png"
                alt="play_one"
                className="h-[250px] w-[250px] sm:w-[200px] sm:h-[200px]"
              />
            </a>

            <div className="flex antialiased space-x-2">
              <div className="px-3 py-2">
                <a
                  href="/gethired"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Get Hired
                </a>
              </div>
              <div className="px-3 py-2">
                <a
                  href="/findtalent"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Find Talent
                </a>
              </div>
              <div className="px-3 py-2">
                <a
                  href="/other"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  About us
                </a>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </div>
  );
}
