import React, { useState } from "react";
import { Navigation } from "components/Nav";
import { AppFooter } from "components/Footer";
import { FaHandshake } from "react-icons/fa";

export default function Policy() {
  return (
    <>
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="bg-black-100">
          <Navigation />

          <div className="font-saira w-full flex justify-center text-white flex flex-col items-center">
            <div className="w-full max-w-4xl px-4 md:px-8">
              <p className="text-base md:text-lg">
                At Talents, we are committed to protecting your privacy and
                ensuring the security of your personal information. This Privacy
                Policy outlines how we collect, use, store, and share your data
                when you use our services. By accessing our website or engaging
                with our recruitment services, you consent to the terms outlined
                in this policy.
              </p>

              <h2 className="text-xl font-bold mt-6">
                1. Information We Collect
              </h2>
              <p>We collect and process the following types of information:</p>
              <ul className="list-disc ml-6">
                <li>
                  <strong>For Talents:</strong>
                  <ul className="list-decimal ml-6">
                    <li>
                      <strong>Personal Information:</strong> Name, location,
                      email address, Telegram handle, LinkedIn profile, GitHub
                      profile, X (Twitter) username, preferred timezone, and
                      contact details.
                    </li>
                    <li>
                      <strong>Professional Details:</strong> CV, professional
                      summary, proof of work, desired job category, skills, work
                      setup preferences (e.g., remote or in-office), Solana
                      community affiliations, and Superteam membership (if
                      applicable).
                    </li>
                    <li>
                      <strong>Additional Information:</strong> Any other details
                      you voluntarily provide in our talent submission form.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>For Companies:</strong>
                  <ul className="list-decimal ml-6">
                    <li>
                      <strong>Business Information:</strong> Company name,
                      address, email address, and publicly available data (e.g.,
                      from job boards).
                    </li>
                    <li>
                      <strong>Job Details:</strong> Job descriptions, required
                      skills, preferred locations, and other hiring needs.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Automatically Collected Information:</strong>
                  <ul className="list-decimal ml-6">
                    <li>
                      When you visit our website, we may collect certain
                      technical data, including your IP address, browser type,
                      operating system, and browsing behavior, through cookies
                      and analytics tools.
                    </li>
                  </ul>
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6">
                2. How We Use Your Information
              </h2>
              <p>We use your information to:</p>
              <ul className="list-disc ml-6">
                <li>
                  Facilitate the recruitment process by matching talents with
                  suitable job opportunities.
                </li>
                <li>
                  Communicate with you about job openings, interview processes,
                  and hiring outcomes.
                </li>
                <li>Improve our services and website functionality.</li>
                <li>Ensure compliance with applicable laws and regulations.</li>
              </ul>

              <h2 className="text-xl font-bold mt-6">3. Data Sharing</h2>
              <p>
                We respect your privacy and only share your data under the
                following conditions:
              </p>
              <ul className="list-disc ml-6">
                <li>
                  <strong>With Companies:</strong> Relevant talent information,
                  such as professional summaries, skills, and CVs, may be shared
                  with companies to facilitate hiring. Only information
                  necessary for the hiring process will be disclosed.
                </li>
                <li>
                  <strong>Third-Party Service Providers:</strong> We may share
                  data with trusted third-party platforms (e.g., Airtable) to
                  store and manage information securely.
                </li>
                <li>
                  <strong>Legal Requirements:</strong> We may disclose data to
                  comply with legal obligations or protect our rights and
                  interests.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6">4. Data Security</h2>
              <p>
                We implement strict security measures to safeguard your data,
                including:
              </p>
              <ul className="list-disc ml-6">
                <li>
                  Storing data in secure Airtable databases with restricted
                  access.
                </li>
                <li>
                  Using encryption and access controls to protect personal
                  information.
                </li>
                <li>
                  While we strive to protect your data, no system can guarantee
                  absolute security. By using our services, you acknowledge and
                  accept this risk.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6">5. Data Retention</h2>
              <p>
                For Talents: Data will be retained indefinitely unless you
                request deletion. This allows us to keep your profile active for
                future opportunities.
              </p>
              <p>
                For Companies: Data will be retained as long as necessary to
                fulfill our service obligations.
              </p>

              <h2 className="text-xl font-bold mt-6">6. Your Rights</h2>
              <p>You have the right to:</p>
              <ul className="list-disc ml-6">
                <li>
                  <strong>Access Your Data:</strong> Request a copy of the
                  personal data we hold about you.
                </li>
                <li>
                  <strong>Update Your Data:</strong> Correct any inaccuracies in
                  your information.
                </li>
                <li>
                  <strong>Delete Your Data:</strong> Request the deletion of
                  your data from our system.
                </li>
                <li>
                  <strong>Withdraw Consent:</strong> Opt-out of data processing
                  or sharing at any time.
                </li>
              </ul>
              <p>
                To exercise these rights, please contact us at{" "}
                <a href="mailto:team@talents.build">team@talents.build</a>
              </p>

              <h2 className="text-xl font-bold mt-6">7. Use of Cookies</h2>
              <p>
                Our website uses cookies to enhance user experience and collect
                analytics. Cookies allow us to:
              </p>
              <ul className="list-disc ml-6">
                <li>Understand how visitors interact with our website.</li>
                <li>Improve website functionality and content.</li>
              </ul>
              <p>
                You can manage or disable cookies through your browser settings.
              </p>

              <h2 className="text-xl font-bold mt-6">
                8. International Data Transfers
              </h2>
              <p>
                As a global recruitment service, we may process data from users
                in different countries. We comply with international data
                protection regulations, including GDPR and Singapore’s PDPA,
                ensuring your information is handled securely and lawfully.
              </p>

              <h2 className="text-xl font-bold mt-6">
                9. Changes to This Privacy Policy
              </h2>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our services or legal requirements. Any updates will
                be posted on this page, and we encourage you to review it
                periodically.
              </p>
            </div>
          </div>

          <div className="w-full">
            <AppFooter />
          </div>
        </div>
      </div>
    </>
  );
}
