import React, { useState } from "react";
import { Navigation } from "components/Nav";
import { AppFooter } from "components/Footer";
import { FaHandshake } from "react-icons/fa";

export function FAQ() {
  return (
    <div>
      <section className="font-saira p-5 bg-black pb-12 pt-20 bg-black lg:pb-[90px] lg:pt-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
                <span className="mb-2 block sm-text-xl text-lg text-orange font-semibold text-primary">
                  FAQ
                </span>
                <h2 className="mb-4 text-3xl font-bold text-white sm:text-2xl md:text-3xl lg:text-[40px] xl:text-[48px] px-4 sm:px-6">
                  Any Questions? Look Here
                </h2>
              </div>
            </div>
          </div>

          <div className="-mx-4 max-h-[800px] flex flex-col">
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="What is the cost of your service?"
                text="We operate on a success-based fee model, meaning you only pay once we successfully place the right talent within your company.
"
              />
              <AccordionItem
                header="How long does it take to find candidates?
"
                text="The time it takes to find the right candidates can vary based on your specific needs. However, we focus on speed and accuracy, leveraging our Solana community connections to present you with highly qualified candidates as quickly as possible"
              />
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="Can you help with niche or highly specialized roles?"
                text="Yes, we specialize in sourcing community talents with a deep understanding of the Solana ecosystem. Whether you need a developer, a designer, or a highly specialized role, we can find candidates who match your specific requirements.
"
              />

              <AccordionItem
                header="What kind of ongoing support do you provide after placement?
"
                text="Our service doesn’t end after a hire. We offer post-hire support, ensuring that the talent smoothly integrates into your team. We also follow up to ensure both the company and talent are satisfied with the match.
"
              />
              <AccordionItem
                header="What industries do you cater to within the Solana ecosystem?"
                text="We cater to various industries within the Solana ecosystem, from DeFi and NFTs to gaming, DePIN, and more. Our goal is to match companies across all verticals with the right community talent.
"
              />
              <AccordionItem
                header="Can we hire on a contract basis?
"
                text="Yes, we offer flexibility in hiring for both full-time roles and contract-based positions. We can match you with community talents available for short-term or long-term engagements, depending on your project needs.

"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="mb-8 w-full rounded-lg bg-transparent text-white sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            color="white"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill="white"
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-bold text-white">{header}</h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-3 leading-relaxed text-body-color italic">{text}</p>
      </div>
    </div>
  );
};

export default function Find() {
  return (
    <>
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="" />
        <div className="bg-black-100">
          <Navigation />

          <div className="font-saira flex flex-col items-center">
            <section className="text-white flex flex-col text-center">
              <div className="max-w-screen-xl md:w-[500px] flex flex-col items-center px-4 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                <h2 className="text-5xl font-bold sm:text-4xl antialiased">
                  Find the Best Solana Community Talent
                </h2>
                <p className="mt-[5%] font-mono text-base font-normal leading-relaxed text-base md:w-[400px] text-white text-center w-[900px]">
                  Looking for top talent who lives and breathes the Solana
                  ecosystem? We connect you with dedicated community talents who
                  understand the culture and required skills for the job.
                </p>
                <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                  <div className="border-t-2 mt-[10%] opacity-[70%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
                </div>
              </div>
            </section>

            <section className="text-white flex flex-col  text-center">
              <h2 className="text-3xl mt-[5%] font-bold sm:text-3xl">
                Why Work With Us?
              </h2>
              <div className="max-w-screen-xl md:w-[500px] flex flex-col items-center px-4 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                <div className="mt-[10%] sm:flex-col mb-[8%] gap-10 flex justify-center items-start sm:items-center">
                  {/* First Card */}
                  <div className="w-[300px] h-full px-4 md:px-6">
                    <div className="flex flex-col items-center">
                     <div className="inline-flex items-center justify-center p-3 mb-3 text-xs font-semibold uppercase h-6 text-orange bg-orange/30 rounded-full sm:text-sm sm:h-8 sm:px-6 sm:mb-4">
                        COMMUNITY
                      </div>
                      <div className="text-3xl font-bold text-white mb-5">
                        Community-Centric
                      </div>
                      <div className="text-white mt-3 text-center block font-mono text-base font-normal leading-relaxed text-white">
                        Our focus is only on Solana community talent, ensuring
                        the candidates we present already understand your
                        ecosystem and blockchain.
                      </div>
                    </div>
                  </div>

                  {/* Second Card */}
                  <div className="w-[300px] h-full px-4 md:px-6">
                    <div className="flex flex-col items-center">
                   <div className="inline-flex items-center justify-center p-3 mb-3 text-xs font-semibold uppercase h-6 text-orange bg-orange/30 rounded-full sm:text-sm sm:h-8 sm:px-6 sm:mb-4">
                        NETWORK
                      </div>
                      <div className="text-3xl font-bold text-white">
                        Pre-Vetted Talents
                      </div>
                      <div className="text-white mt-6 text-center block font-mono text-base font-normal leading-relaxed text-white">
                        We evaluate every candidate's skills, experience, and
                        personal fit before presenting them to you to assure the
                        highest quality match for your needs.
                      </div>
                    </div>
                  </div>

                  {/* Third Card */}
                  <div className="w-[300px] h-full px-4 md:px-6">
                    <div className="flex flex-col items-center">
                     <div className="inline-flex items-center justify-center p-3 mb-3 text-xs font-semibold uppercase h-6 text-orange bg-orange/30 rounded-full sm:text-sm sm:h-8 sm:px-6 sm:mb-4">
                        SUPPORT
                      </div>
                      <div className="text-3xl w-[1000px] font-bold text-white">
                        Streamlined Process
                      </div>
                      <div className="text-white mt-6 text-center block font-mono text-base font-normal leading-relaxed text-white">
                        We understand that you have to use your resources
                        wisely! Therefore, we handle everything from sourcing
                        candidates to managing interview logistics, saving you
                        time and resources.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="py-1">
              <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                <div className="border-t-2 mt-[10%] opacity-[70%] mb-[10%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
              </div>

              <div className="relative mb-[10%] w-[100%] md:w-[95%] p-5 flex max-w-4xl items-center">
                <iframe
                  className="bg-transparent rounded-lg"
                  src="https://airtable.com/embed/appBm4ITQiCAgZnRR/pag3UsNPeL1wpRrSZ/form"
                  frameBorder="0"
                  width="100%"
                  height="833"
                  style={{
                    background: "transparent",
                    border: "1px solid #ccc",
                  }}
                ></iframe>
              </div>

              <div className="container max-w-screen-xl md:w-[500px] mx-auto px-6 space-y-8 text-white">
                <h2 className="text-3xl font-bold text-center sm:text-3xl">
                  What Happens Next?
                </h2>

                <div className="flex items-center justify-center mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                  {/* Step 1 */}
                  <div className="relative rounded-lg group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-orange"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          1. Fill Out the Form
                        </h5>
                        <p className="font-mono text-base font-normal leading-relaxed text-sm text-white">
                          Tell us about your hiring needs by completing the
                          quick form below.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Step 2 */}
                  <div className="relative rounded-lg group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-orange"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                        />
                      </svg>
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          2. We'll Reach Out
                        </h5>
                        <p className="font-mono text-base font-normal leading-relaxed text-sm text-white">
                          Our team will connect with you to understand your
                          requirements and company culture.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Step 3 */}
                  <div className="relative rounded-lg group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-orange"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          3. We will get in touch
                        </h5>
                        <p className="font-mono text-base font-normal leading-relaxed text-sm text-white">
                          We’ll hand-pick talents that match your needs and
                          introduce them to your team.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Step 4 */}
                  <div className="relative rounded-lg group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <FaHandshake className="text-orange" size={25} />
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          4. Hire The Best
                        </h5>
                        <p className="font-mono text-base font-normal leading-relaxed text-sm text-white">
                          Conduct interviews, choose the perfect candidate, and
                          onboard them with ease.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex md:mt-[30%] sm:mb-[40%] items-center justify-center w-full h-screen">
            <div className="w-[450px] overflow-hidden">
              <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                <div className="border-t-2 mt-[5%] opacity-[70%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
              </div>
              <FAQ />
            </div>
          </div>
          <div className="w-full">
            <AppFooter />
          </div>
        </div>
      </div>
    </>
  );
}
