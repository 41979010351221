import React, { useState } from "react";
import { Img } from "../../components";
import { AppFooter } from "components/Footer";
import { Navigation } from "components/Nav";

export default function Other() {
  return (
    <>
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="bg-black-100">
          <Navigation />

          <div className="font-saira flex flex-col items-center">
            <section className="text-white flex flex-col text-center">
              <div className="max-w-screen-xl flex flex-col items-center px-4 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                <h4 className="block mb-2 font-saira text-3xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                  The story behind Talents
                </h4>
                <p className="block w-[510px] md:w-[100%] mb-8 mt-5 font-mono text-base antialiased font-normal leading-relaxed text-white">
                  Since the very beginning, we’ve been deeply ingrained in the
                  Solana ecosystem, contributing to its growth and success. As
                  the ecosystem flourished, a recurring challenge became clear:
                  finding exceptional talent—individuals who are not only
                  skilled but also deeply rooted in Solana’s vibrant and
                  committed community.
                </p>

                <p className="block w-[550px] md:w-[100%] mb-8 mt-5 font-mono text-base antialiased font-normal leading-relaxed text-white">
                  Driven by our passion for fostering Solana’s growth and
                  empowering its community, we created Talents. Our mission is
                  to bridge the gap between forward-thinking companies and
                  community-driven experts, helping both thrive in a rapidly
                  evolving Solana ecosystem
                </p>
                <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                  <div className="border-t-2 mt-[10%] opacity-[70%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
                </div>
              </div>
            </section>

            <div className="py-1 mb-[5%]">
              <div className="container max-w-screen-md mx-auto px-6 space-y-8 text-white">
                <div className="mt-16 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                  {/* Telegram Card */}
                  <div className="p-6 rounded-lg shadow-md flex flex-col items-center">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20">
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Telegram</title>
                        <path d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z" />
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium">Telegram</h2>
                    <p className="mt-2 text-sm">
                      <a
                        href="https://telegram.me/Yannick_talents"
                        target="_blank"
                        className="text-white hover:text-orange"
                        rel="noopener noreferrer"
                      >
                        Telegram
                      </a>
                    </p>
                  </div>

                  <div className="p-6 rounded-lg shadow-md flex flex-col items-center">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20">
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium">Email</h2>
                    <p className="mt-2 text-sm">team@talents.build</p>
                  </div>

                  <div className="p-6 rounded-lg shadow-md flex flex-col items-center">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="30"
                        fill="currentColor"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium">LinkedIn</h2>
                    <p className="mt-2 text-sm">
                      <a
                        href="https://www.linkedin.com/company/talents-build/about/?viewAsMember=true"
                        target="_blank"
                        className="text-white hover:text-orange"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </p>
                  </div>

                  {/* Twitter Card */}
                  <div className="p-6 rounded-lg shadow-md flex flex-col items-center">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        version="1.2"
                        fill="currentColor"
                        viewBox="0 0 300 271"
                      >
                        <path
                          fillRule="evenodd"
                          d="M236 0h46L181 115l118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123L-1.1 0h94.9l65.5 86.6zm-16.1 244h25.5L80.4 26H53z"
                        ></path>
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium">Twitter</h2>
                    <p className="mt-2 text-sm">
                      <a
                        href="https://x.com/talents_build"
                        className="text-white hover:text-orange"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
              <AppFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
