import React, { useState, useEffect, useRef } from "react";

import { twMerge } from "tailwind-merge";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { AppFooter } from "components/Footer";
import { Marquee } from "components/Slidebar";
import { Navigation } from "components/Nav";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import * as THREE from "three";

export const Wins = () => {
  return (
    <div>
      <h3 className="block mb-2 font-saira text-3xl antialiased font-semibold text-blue-gray-900">
        Why Talents
      </h3>
      <div className="flex sm:grid sm:grid-cols-1 items-center justify-center gap-10 font-saira">
        <div className="mb-[4%]">
          <div className="text-base text-gray-600">
            <dt className="font-semibold text-white text-center py-5">
              <div className="text-2xl text-orange">For Talent</div>
            </dt>
            <div className="space-y-4 sm:ml-[10%]">
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">1.</span>
                Leading jobs on Solana
              </p>
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">2.</span>
                Interview guidance
              </p>
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">3.</span>
                Ongoing career support
              </p>
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">4.</span>
                Hidden job opportunities
              </p>
            </div>
          </div>
        </div>

        <div className="mb-[4%]">
          <div className="text-base text-gray-600">
            <dt className="font-semibold text-white text-center py-5">
              <div className="text-2xl text-orange">For Projects</div>
            </dt>
            <div className="space-y-4 sm:ml-[10%]">
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">1.</span>
                Seamless recruiting process
              </p>
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">2.</span>
                Access to community members
              </p>
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">3.</span>
                Success-based payments
              </p>
              <p className="font-mono text-white/90 flex items-center">
                <span className="text-2xl mr-2">4.</span>
                Character-focused evaluation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const logos = [
  { src: "/images/m1.png", alt: "M1" },
  { src: "/images/m2.png", alt: "M2" },
  { src: "/images/m3.png", alt: "M3" },
  { src: "/images/m4.png", alt: "M4" },
  { src: "/images/m5.png", alt: "M5" },
  { src: "/images/m6.png", alt: "M6" },
];

const slides = [
  {
    title: "Discovery Call",
    description:
      "We begin by getting to know you and your business during a detailed discovery call. This allows us to understand your company’s culture, goals, and specific hiring needs to tailor our approach for maximum success.",
  },
  {
    title: "Sourcing Talent",
    description:
      "We leverage our extensive network within the Solana ecosystem and its vibrant communities to identify top-tier candidates. By connecting with the most active and engaged contributors, we ensure we find talent that aligns seamlessly with your vision and goals.",
  },
  {
    title: "Screening and Evaluation",
    description:
      "Each candidate undergoes a thorough screening process, including resume analysis and behavioural interviews. We evaluate their technical expertise, cultural fit, and alignment with your role requirements before presenting them to you.",
  },
  {
    title: "Talent Presentation",
    description:
      "We share a curated shortlist of top candidates, complete with detailed profiles and insights from our evaluations. This ensures you have all the information you need to make informed decisions quickly.",
  },
  {
    title: "Coordination of Interviews",
    description:
      "We handle the logistics of scheduling interviews to ensure a seamless experience for both you and the candidates. From initial meetings to final discussions, we keep everything on track to avoid delays.",
  },
  {
    title: "Feedback and Selection",
    description:
      "After interviews, we gather feedback from both sides to refine the process and address any concerns. Once you’ve made your decision, we support you through the offer stage to secure your ideal hire.",
  },
];

export const Partners = () => {
  return (
    <div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
      <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8">
        {logos.map((logo, index) => (
          <li key={index}>
            <img
              src={logo.src}
              alt={logo.alt}
              className="w-[300px] h-[300px] rounded-lg object-contain"
            />
          </li>
        ))}
      </ul>
      <ul
        className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8"
        aria-hidden="true"
      >
        {logos.map((logo, index) => (
          <li key={index}>
            <img
              src={logo.src}
              alt={logo.alt}
              className="w-[300px] h-[300px] rounded-lg object-contain"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export const AboutPane = () => {
  const isDesktop = useMediaQuery({ maxWidth: 767 });

  return !isDesktop ? (
    <div></div>
  ) : (
    <div className="mt-[4%] grid gap-10 w-[100%] h-[2100px] px-6 xl:w-2/3 self-center"></div>
  );
};

export function FAQ() {
  return (
    <div>
      <section className="font-saira p-5 bg-black pb-12 pt-20 bg-black lg:pb-[90px] lg:pt-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
                <span className="mb-2 block sm-text-xl text-lg text-orange font-semibold text-primary">
                  FAQ
                </span>
                <h2 className="mb-4 text-3xl font-bold text-white sm:text-2xl md:text-3xl lg:text-[40px] xl:text-[48px] px-4 sm:px-6">
                  Any Questions? Look Here
                </h2>
              </div>
            </div>
          </div>

          <div className="-mx-4 max-h-[800px] flex flex-col">
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="Who can join your talent pool?"
                text="Any active member of the Solana community, including developers, marketers, and designers, looking for new career opportunities within the ecosystem."
              />
              <AccordionItem
                header="How do you match me with a job?"
                text="After you join the talent pool, we carefully review your profile and get in touch with you to better understand your goals, requirements, and needs. This allows us to match you with job openings that align with your skills and aspirations, ensuring we create the best fit for you."
              />
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="What if I don’t find a job right away?"
                text="If an immediate match isn’t available, don’t worry. We’ll keep your profile in our talent pool and notify you when new opportunities that align with your goals become available."
              />

              <AccordionItem
                header="Can I update my profile after joining the talent pool?"
                text="Yes, you can update your profile at any time. Just get in touch with us, and we’ll ensure that your latest skills and preferences are reflected in our matching process."
              />
              <AccordionItem
                header="How is my data protected?"
                text="We take your privacy seriously. Your personal information is kept secure and is only used for the purpose of matching you with relevant job opportunities. We do not share your data with any third party without your explicit consent."
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="mb-8 w-full rounded-lg bg-transparent text-white sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            color="white"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill="white"
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-bold text-white">{header}</h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-3 leading-relaxed text-body-color italic">{text}</p>
      </div>
    </div>
  );
};

export const Carousel = ({ slides }) => {
  const [current, setCurrent] = useState(0);

  const previousSlide = () => {
    setCurrent(current === 0 ? slides.length - 1 : current - 1);
  };

  const nextSlide = () => {
    setCurrent(current === slides.length - 1 ? 0 : current + 1);
  };

  return (
    <div className="w-full sm:hidden md:hidden p-5 h-auto overflow-hidden relative">
      <div className="">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`w-full flex flex-col items-center ${
              current === index ? "block" : "hidden"
            }`}
          >
            <div className="w-full h-full text-white flex items-center justify-center relative">
              <h1 className="absolute left-20 text-[220px] font-bold text-orange opacity-30">
                {index + 1}
              </h1>

              <div className="relative z-10 flex flex-row items-center justify-between w-full px-10">
                <div className="flex flex-col items-start">
                  <h1 className="text-4xl ml-[50%] w-[300px] text-white">
                    {slide.title}
                  </h1>
                </div>

                <div className="flex flex-col justify-center items-center w-[50%]">
                  <p className="text-xl mt-[15%] block font-normal leading-relaxed max-h-min font-mono text-base antialiased text-left">
                    {slide.description}
                  </p>
                  <div className="flex py-5 items-center justify-center">
                    <button
                      onClick={previousSlide}
                      className="text-white bg-black text-xl p-4 rounded-full transition-transform duration-300 hover:scale-110"
                    >
                      <FaArrowCircleLeft size={50}></FaArrowCircleLeft>
                    </button>
                    <button
                      onClick={nextSlide}
                      className="text-white bg-black text-xl p-4 rounded-full transition-transform duration-300 hover:scale-110"
                    >
                      <FaArrowCircleRight size={50}></FaArrowCircleRight>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function Landing() {
  const { ref: partnersRef, inView: partnersInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: winsRef, inView: winsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: winsRef2, inView: winsInView2 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: card1Ref, inView: card1InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: card2Ref, inView: card2InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: card3Ref, inView: card3InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
      <div className="relative overflow-hidden inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="" />
        <div className="bg-black-100">
          <Navigation />

          <div className="font-saira">
            <div className="mt-[12%] font-saira relative">
              <div className="absolute flex flex-col items-center justify-center inset-0">
                <img
                  src={"/images/ball.png"}
                  alt="spinning globe"
                  className="w-[420px] mt-[8%] h-[420px] animate-spinGlobe object-cover opacity-30"
                />
              </div>
            </div>

            <div className="relative z-10 pt-[10px] fade-in-up">
              <div className="flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
                <h2 className="text-center text-5xl sm:text-3xl text-white antialiased leading-none uppercase w-[40%] sm:w-[450px] w-full">
                  Building careers in the Solana ecosystem
                </h2>

                <p className="mt-6 text-center text-base w-[900px] sm:text-sm sm:w-[400px] md:text-xs px-4 sm:px-7 lg:px-8 leading-6 text-white font-mono text-base antialiased font-normal leading-relaxed uppercase">
                  We bridge the gap between leading Solana projects and
                  community-driven talent, helping both thrive in a rapidly
                  growing Solana ecosystem.
                </p>

                <div className="mt-[40px] flex gap-4">
                  <a href="/gethired" className="inline-flex items-center">
                    <button className="relative inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        Get Hired
                      </span>
                    </button>
                  </a>

                  <a href="/findtalent" className="inline-flex items-center">
                    <button className="relative inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_0deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        Find Talent
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center text-4xl font-medium text-white sm:text-3xl transition-transform duration-1000 ease-out">
            <div className="font-saira fade-in-up mt-[10%] items-center md:mt-[5%] text-white flex flex-col justify-center text-3xl w-full" />

            <div className="font-saira grid grid-rows-2 md:grid-cols-1 w-[45%] md:w-[100%] p-10 text-center justify-items-center mx-auto mb-[-5%] lg:mb-40" />

            <div className="flex flex-col justify-center items-center p-2">
              <div className="mx-auto w-full">
                <div className="items-center justify-center mb-[7%]">
                  <Wins />
                </div>

                <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                  <div className="border-t-2 opacity-[70%] mt-[0%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
                </div>

                <section className="text-white text-center">
                  <div className="items-center px-4 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <h2 className="text-3xl mt-[5%] font-bold sm:text-3xl">
                   Why to join our Talent Pool?
                    </h2>

                    <div className="mt-[5%] sm:mt-[20%] sm:flex-col mb-[8%] gap-10 flex justify-center items-start sm:items-center">
                      {/* First Card */}
                      <div className="w-[400px] h-full px-4 md:px-6">
                        <div className="flex flex-col items-center">
                          <div className="inline-flex items-center justify-center p-3 mb-3 text-xs font-semibold uppercase h-6 text-orange bg-orange/30 rounded-full sm:text-sm sm:h-8 sm:px-6 sm:mb-4">
                            COMMUNITY
                          </div>
                          <div className="text-3xl font-bold text-white">
                            Community-Driven
                          </div>
                          <div className="text-white mt-6 max-w-[300px] mx-auto text-center font-mono text-base font-normal leading-relaxed">
                            We connect you with roles that align with their
                            skills and passions, allowing you to play a more
                            significant part within the community.
                          </div>
                        </div>
                      </div>

                      {/* Second Card */}
                      <div className="w-[400px] h-full px-4 md:px-6">
                        <div className="flex flex-col items-center">
                          <div className="inline-flex items-center justify-center p-3 mb-3 text-xs font-semibold uppercase h-6 text-orange bg-orange/30 rounded-full sm:text-sm sm:h-8 sm:px-6 sm:mb-4">
                            NETWORK
                          </div>
                          <div className="text-3xl font-bold text-white">
                            Exclusive Roles
                          </div>
                          <div className="text-white mt-6 max-w-[300px] mx-auto text-center font-mono text-base font-normal leading-relaxed">
                            Get access to job opportunities in the Solana
                            ecosystem. We work with many projects, and some of
                            the jobs are not listed, increasing your chance of
                            landing exclusive positions.
                          </div>
                        </div>
                      </div>

                      {/* Third Card */}
                      <div className="w-[400px] h-full px-4 md:px-6">
                        <div className="flex flex-col items-center">
                          <div className="inline-flex items-center justify-center p-3 mb-3 text-xs font-semibold uppercase h-6 text-orange bg-orange/30 rounded-full sm:text-sm sm:h-8 sm:px-6 sm:mb-4">
                            SUPPORT
                          </div>
                          <div className="text-3xl font-bold text-white">
                            Ongoing Support
                          </div>
                          <div className="text-white mt-6 max-w-[300px] mx-auto text-center font-mono text-base font-normal leading-relaxed">
                            We support you through the entire hiring
                            process—from interview prep to securing the right
                            offer—and guide you in your long-term growth within
                            the ecosystem, ensuring continued development and
                            success.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                  <div className="border-t-2 opacity-[70%] mt-[0%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
                </div>

                <div className="flex justify-center mb-6">
                  <Partners />
                </div>

                <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                  <div className="border-t-2 opacity-[70%] mt-[5%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
                </div>

                <div className="w-[100%] sm:hidden md:hidden mx-auto pt-11">
                  <h4 className="block mb-2 font-sairatext-3xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    Client Process
                  </h4>
                  <Carousel slides={slides} />
                </div>

                <div className="relative mt-[10%] mb-[10%] lg:pt-64">
                  <div className="p-4 sm:p-6 text-center">
                    <a href="#">
                      <h3 className="text-2xl text-white font-saira mb-6">
                        Join Us Now
                      </h3>
                    </a>

                    <div className="mt-[20px] flex items-center justify-center gap-4">
                      <a
                        href="/gethired"
                        className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                      >
                        <button className="relative font-saira inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                            Get Hired
                          </span>
                        </button>
                      </a>

                      <a
                        href="/findtalent"
                        className="inline-flex items-center"
                      >
                        <button className="relative font-saira inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_0deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                            Find Talent
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <AppFooter />
          </div>
        </div>
      </div>
    </>
  );
}
