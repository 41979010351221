import React, { useState } from "react";
import { Img } from "../../components";
import { AppFooter } from "components/Footer";
import { FaHandshake } from "react-icons/fa";
import { Navigation } from "components/Nav";

export function FAQ() {
  return (
    <div>
      <section className="font-saira p-5 bg-black pb-12 pt-20 bg-black lg:pb-[90px] lg:pt-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
                <span className="mb-2 block sm-text-xl text-lg text-orange font-semibold text-primary">
                  FAQ
                </span>
                <h2 className="mb-4 text-3xl font-bold text-white sm:text-2xl md:text-3xl lg:text-[40px] xl:text-[48px] px-4 sm:px-6">
                  Any Questions? Look Here
                </h2>
              </div>
            </div>
          </div>

          <div className="-mx-4 max-h-[800px] flex flex-col">
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="Who can join your talent pool?"
                text="Any active member of the Solana community, including developers, marketers, and designers, looking for new career opportunities within the ecosystem."
              />
              <AccordionItem
                header="How do you match me with a job?"
                text="After you join the talent pool, we carefully review your profile and get in touch with you to better understand your goals, requirements, and needs. This allows us to match you with job openings that align with your skills and aspirations, ensuring we create the best fit for you."
              />
              <AccordionItem
                header="Who can join our Talent Pool?"
                text="Any active member or enthusiast of the Solana community, including developers, marketers, and designers, looking for new career opportunities within the ecosystem."
              />
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="What if I don’t find a job right away?"
                text="If an immediate match isn’t available, don’t worry. We’ll keep your profile in our talent pool and notify you when new opportunities that align with your goals become available."
              />

              <AccordionItem
                header="Can I update my profile after joining the talent pool?"
                text="Yes, you can update your profile at any time. Just get in touch with us, and we’ll ensure that your latest skills and preferences are reflected in our matching process."
              />
              <AccordionItem
                header="How is my data protected?"
                text="We take your privacy seriously. Your personal information is kept secure and is only used for the purpose of matching you with relevant job opportunities. We do not share your data with any third party without your explicit consent."
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="mb-8 w-full rounded-lg bg-transparent text-white sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            color="white"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill="white"
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-bold text-white">{header}</h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-3 leading-relaxed text-body-color italic">{text}</p>
      </div>
    </div>
  );
};

export default function Hired() {
  return (
    <>
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="" />
        <div className="bg-black-100">
          <Navigation />

          <div className="font-saira flex flex-col items-center">
            <section className="text-white flex flex-col text-center">
              <div className="max-w-screen-xl md:w-[500px] flex flex-col antialiased items-center px-4 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                <h2 className="text-5xl font-bold sm:text-4xl">
                  Find a job within the Solana Ecosystem
                </h2>
                <p className="mt-[5%] font-mono text-base antialiased font-normal leading-relaxed text-base md:w-[400px] text-white text-center w-[900px]">
                  As an embedded community member or Solana enthusiast, you’re
                  already part of something special. Now, let us help you grow
                  and develop by connecting you with exciting job opportunities
                  within the ecosystem. Join our talent pool and gain access to
                  exclusive roles with leading projects focused on Solana’s
                  future.
                </p>
                <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                  <div className="border-t-2 mt-[10%] opacity-[70%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
                </div>
              </div>
            </section>

            <div className="py-1">

              <div className="w-[1000px] mb-[10%] md:w-[95%] p-5 flex max-w-4xl items-center">
                <iframe
                  className="bg-transparent rounded-lg"
                  src="https://airtable.com/embed/appBm4ITQiCAgZnRR/pagT5RM0zZcAzxJwM/form"
                  frameBorder="0"
                  width="100%"
                  height="833"
                  style={{
                    background: "transparent",
                    border: "1px solid #ccc",
                  }}
                ></iframe>
              </div>

              <div className="container max-w-screen-xl md:w-[500px] mx-auto px-6 space-y-8 text-white">
                <h2 className="text-3xl font-bold text-center sm:text-3xl">
                  What Happens Next?
                </h2>

                <div className="flex items-center justify-center flex-col mt-16 gap-6">
                  {/* Step 1 */}
                  <div className="rounded-lg items-center justify-center group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-orange"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          1. Fill Out the Form
                        </h5>
                        <p className="text-sm text-white font-mono text-base font-normal leading-relaxed w-[400px]">
                          Share your information with us using the simple form
                          below.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Step 2 */}
                  <div className="rounded-lg items-center justify-center group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-orange"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                        />
                      </svg>
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          2. Job Matching
                        </h5>
                        <p className="text-sm text-white font-mono text-base font-normal leading-relaxed w-[400px]">
                          We match your profile with the most suitable roles in
                          the Solana ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-lg items-center justify-center group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-orange"
                        viewBox="0 0 50 50"
                      >
                        <path d="M25 2C12.317 2 2 12.317 2 25s10.317 23 23 23 23-10.317 23-23c0-4.56-1.34-8.81-3.637-12.389l-1.369 1.618A20.85 20.85 0 0 1 46 25c0 11.579-9.421 21-21 21S4 36.579 4 25 13.421 4 25 4c5.443 0 10.394 2.1 14.129 5.51l1.309-1.545A22.9 22.9 0 0 0 25 2m18.236 5.754-19.322 22.8-8.133-7.585-1.363 1.463 9.666 9.015 20.68-24.4z"></path>
                      </svg>
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          3. Getting to Know You Better
                        </h5>
                        <p className="text-sm text-white w-[400px] font-mono text-base font-normal leading-relaxed">
                          Once we receive your feedback on a job, we’ll organize
                          a meeting to learn more about your goals and skills.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-lg items-center justify-center group transition hover:z-[1] hover:shadow-2xl">
                    <div className="relative p-8 space-y-8">
                      <FaHandshake className="text-orange" size={25} />
                      <div className="space-y-2">
                        <h5 className="text-xl text-white font-medium transition group-hover:text-orange">
                          4. Get Hired
                        </h5>
                        <p className="text-sm text-white w-[400px] font-mono text-base font-normal leading-relaxed">
                          We support you throughout the interview process and
                          make sure you get hired.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex md:mt-[30%] sm:mb-[40%] items-center justify-center w-full h-screen">
            <div className="w-[450px] overflow-hidden">
              <div className="font-saira fade-in-up items-center text-white flex flex-col justify-center text-3xl w-full">
                <div className="border-t-2 mt-[5%] opacity-[70%] mb-[5%] md:mt-[0%] md:hidden border-orange w-[150px] mx-auto"></div>
              </div>
              <FAQ />
            </div>
          </div>
          <div className="w-full">
            <AppFooter />
          </div>
        </div>
      </div>
    </>
  );
}
